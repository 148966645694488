<template lang="pug">
.page.help
  error-layout(
    v-if="$fetchState.error"
    :error="$fetchState.error")

  .container(v-else)
    .page__layout
      .page__aside
        .navigation
          .navigation__toggler
            el-button(@click="expanded = !expanded" type="primary")
              i.bx.bx-food-menu

          .navigation__search
            el-input(
              type="search"
              v-model="q"
              placeholder="Search..."
              @keydown.native.enter="search")
              el-button(slot="append" @click="search")
                i.bx.bx-search

        .topics(:class="{ expanded }" @click="expanded = false")
          nuxt-link.topic(
            v-for="i, k in topics"
            :key="k"
            :to="{ name: 'help-center-slug', params: { slug: i.slug } }")
            .topic__icon
              i.bx(:class="i.icon || 'bx-info-circle'")
            .topic__info
              .topic__title(v-text="i.title")
              .topic__summary(v-text="i.summary")

      .page__content
        nuxt-child(:key="$route.params.slug")
</template>

<script>
import ErrorLayout from '~/layouts/error'

export default {
  components: {
    ErrorLayout
  },

  data () {
    return {
      topics: [],
      expanded: false,
      q: this.$route.query.q
    }
  },

  fetch () {
    return this.$contentful.client
      .getEntries({
        content_type: 'topic',
        order: '-fields.priority,sys.createdAt',
        select: [
          'fields.slug',
          'fields.title',
          'fields.summary',
          'fields.icon'
        ].join(',')
      })
      .then(res => {
        this.topics = res.items
          .map(i => i?.fields || {})
      })
      .catch(e => {
        const error = { statusCode: 503 }
        if (process.server) {
          this.$nuxt.context.res.statusCode = error.statusCode
          this.$fetchState.error = error
        } else {
          this.$nuxt.error(error)
        }
      })
  },

  watch: {
    '$route.query.q' (val) {
      this.q = val
    }
  },

  methods: {
    search () {
      this.$router.push({
        name: 'help-center-search',
        query: {
          q: this.q
        }
      })?.catch(this.$debug.log)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .container {
    max-width: 1367px;
  }
  &__layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include media(md) {
      flex-direction: row;
    }
    > * {
      min-width: 0;
    }
  }
  &__aside {
    position: sticky;
    top: 61px;
    width: 100%;
    background: white;
    z-index: 10;
    border-bottom: $--border-light;
    @include media(md) {
      flex: 0 280px;
      top: 92px;
      overflow-y: scroll;
      max-height: calc(100vh - 92px);
      padding: 1.5em 1.5rem 1.5rem 0;
      border-right: $--border-light;
      border-bottom: none;
    }
  }
  &__content {
    flex: 1;
  }
}
.navigation {
  display: flex;
  gap: .5rem;
  padding: 1rem 0;
  @include media(md) {
    padding: 0.25rem 0 1.5rem 0;
  }
  > * {
    min-width: 0;
  }
  &__toggler {
    @include media(md) {
      display: none;
    }
  }
  &__search {
    flex: 1;
  }
  .el-button {
    padding: 10px 1rem;
    i {
      font-size: 1.25rem;
    }
  }
}
.topics {
  display: grid;
  gap: 1rem;
  @include media(sm-down) {
    display: none;
    gap: .25rem;
    padding-bottom: 1rem;
    &.expanded {
      display: grid;
    }
  }
  .topic {
    min-width: 0;
    display: flex;
    gap: .75em;
    background: $--color-page-background;
    border-radius: $--radius;
    padding: 1em .75em;
    &.nuxt-link-active {
      background: $--color-primary;
      .topic {
        &__icon,
        &__title,
        &__summary {
          color: white;
        }
      }
    }
    &__icon {
      flex: 0 24px;
      font-size: 24px;
      color: $--color-text-secondary;
      text-align: center;
      margin-top: -.2em;
    }
    &__info {
      flex: 1;
    }
    &__title {
      font-weight: $--font-weight-bold;
    }
    &__summary {
      font-size: 13px;
      margin-top: .25em;
      color: $--color-text-secondary;
    }
  }
}
</style>
